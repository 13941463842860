import dayjs from 'dayjs'
import { TEST_ID } from '~/constants/cypress'
import type * as TYPES from '../PanelDelay.types'

// Delay Options Presented to the User at CTAs
// IDEA: Provide a default and allow Contentful to provide the ranges
const delayOptions: TYPES.DelayOption[] = [
  { number: 2, unit: 'week' },
  { number: 3, unit: 'week' },
  { number: 4, unit: 'week' },
]

const useDelayOptions: TYPES.useDelayOptionsHook = (subscription) => {
  const currentBillDate = subscription.periodEndDate

  // Used to construct human readable dates from the delay options
  const addTimeToBillDate = (
    currentBillDate: string,
    num: number,
    unit: dayjs.OpUnitType
  ) => {
    return dayjs(currentBillDate).add(num, unit)
  }

  // Filter out the delay options to only include options
  // that are within a 6 month window of the current bill date.
  const filteredDelayOptions = delayOptions.filter(({ number, unit }) => {
    return addTimeToBillDate(currentBillDate, number, unit).isBefore(
      dayjs().add(6, 'month')
    )
  })

  return filteredDelayOptions.map(({ number, unit }) => {
    const newBillDate = addTimeToBillDate(currentBillDate, number, unit)
    return {
      cypressId: TEST_ID.CANCEL_FLOW_OPTION,
      newBillDate: newBillDate,
      dateString: {
        new: dayjs(newBillDate).format('YYYY-MM-DD'),
        previous: dayjs(currentBillDate).format('YYYY-MM-DD'),
      },
      text: `Delay ${number} ${unit}s (${addTimeToBillDate(
        currentBillDate,
        number,
        unit
      ).format('MMM D')})`,
    }
  })
}

export default useDelayOptions
