import { Dayjs } from 'dayjs'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import { LoadingPanel } from '~/components/CancelFlowPanels/LoadingPanel/LoadingPanel'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import { mutateSubscription } from '~/hooks/mutateSubscription'
import useDelayOptions from './hooks/useDelayOptions'
import PanelDelayUI from './PanelDelay.ui'
import { SubscriptionContext } from '~/context/subscription'
import dayjs from 'dayjs'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

const PanelDelay: React.FC<CancelPanelProps> = ({
  panel,
  handleModalClose,
}) => {
  const [submitting, setSubmitting] = React.useState(false)
  const showToast = React.useContext(ToastContext)
  const { subscription } = React.useContext(SubscriptionContext)
  const [updateSubscription] = mutateSubscription('cancel_flow')

  // The report an issue flow doesn't use the reach router history
  // so setting the fallback to an empty object
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext) || {}

  const delayOptions = useDelayOptions(subscription)

  const updateBillDate = React.useCallback(
    (newBillDate: Dayjs) => {
      if (submitting === true) return
      setSubmitting(true)

      return updateSubscription(
        {
          ...subscription,
          periodEndDate: newBillDate.format('YYYY-MM-DD'),
        },
        {
          onSuccess: () => {
            showToast('success', {
              children: 'Success! You have updated your bill date.',
            })

            trackCancellationSave(
              CANCEL_SAVE_ACTIONS.DELAY_BY_CANCEL_FLOW,
              getHistoryEntry(1).pathname,
              getHistoryEntry(2).state.text
            )
          },
          onError: () => {
            showToast('error', {
              children:
                'There was an error updating your bill date. Please try again.',
            })
          },
          onSettled: () => {
            handleModalClose()
          },
        }
      )
    },
    [
      getHistoryEntry,
      handleModalClose,
      showToast,
      submitting,
      subscription,
      updateSubscription,
    ]
  )

  // The panel.cancelSaveComponent can optionally render an appended next bill date notice to the subhead.
  let subhead = panel.subhead ? `${panel.subhead} ` : ''
  if (panel.cancelSaveComponent.includes('Show Next Bill Date')) {
    subhead += `Your next box bills ${dayjs(subscription.periodEndDate).format(
      'MMMM D'
    )}.`
  }

  return !submitting ? (
    <PanelDelayUI
      delayOptions={delayOptions}
      onChangeBillDateHandler={updateBillDate}
      panel={{
        ...panel,
        subhead: subhead,
      }}
    />
  ) : (
    <LoadingPanel />
  )
}

export default PanelDelay
