import { Grid } from '@chakra-ui/core'
import { rem, styled } from 'design'
import { Link } from 'gatsby'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import { TEST_ID } from '~/constants/cypress'
import { ModalHeader } from 'design/components/Modal/Modal'
import { Body } from 'design/components/Typography/Typography'
import type { PanelDelayUIProps } from './PanelDelay.types'
import StopMySubscriptionButton from '../../StopMySubscriptionButton/StopMySubscriptionButton'

const PanelDelayUI: React.FC<PanelDelayUIProps> = ({
  panel,
  onChangeBillDateHandler,
  delayOptions,
}) => {
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>
      <Body mb={rem(8)}>{panel.subhead}</Body>
      <Grid gap={rem(16)} gridTemplateColumns="1 fr">
        {delayOptions.map((option, index) => {
          return (
            <ModalItemSmall
              data-cy={option.cypressId}
              data-new-bill-date={option.dateString.new}
              data-prev-bill-date={option.dateString.previous}
              key={index}
              onClick={() => onChangeBillDateHandler(option.newBillDate)}
            >
              <p>{option.text}</p>
            </ModalItemSmall>
          )
        })}
        <ModalItemLink
          as={Link}
          data-cy={TEST_ID.CANCEL_FLOW_SAVE}
          fontSize={rem(14)}
          size="sm"
          to="/account/box-settings/edit-bill-date?source=cancelflow"
        >
          Select a date
        </ModalItemLink>
        <StopMySubscriptionButton />
      </Grid>
    </PanelLayout>
  )
}

const StyledLink = styled(Link)``
const ModalItemLink = StyledLink.withComponent(ModalItemSmall)

export default PanelDelayUI
